import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
function SEO({
  description,
  lang = 'en',
  meta,
  keywords,
  title,
  image = false,
  type,
  url
}) {
  const { site } = useStaticQuery(detailsQuery)

  const metaDescription = description || site.siteMetadata?.description
  const ogType = type || 'website'
  const ogUrl = url || 'http://www.matyscarpentryllc.com'
  const ogImage =
    image ||
    'https://matyscarpentry.netlify.app/static/57a3cc0e022db052bc1bd5367e1eacca/14b42/kitchen_74.jpg'

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: 'og:image',
          content: ogImage
        },
        {
          property: `og:url`,
          content: ogUrl
        },
        {
          property: `og:type`,
          content: ogType
        },
        {
          name: `twitter:card`,
          content: image ? 'summary_large_image' : 'summary'
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          property: 'twitter:image',
          content: ogImage
        },
        {
          name: `twitter:site`,
          content: ogUrl
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://www.matyscarpentryllc.com",
            "name": "Matys Carpentry",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+(203) 253 6358",
              "contactType": "CEO"
            }
          }
        `}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
