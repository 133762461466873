import React, { useCallback, useRef } from 'react'
import { Link } from 'gatsby'

import Logo from '../../images/9a.png'

function Header({ siteTitle }) {
  //@ts-ignore
  const toggleRef = useRef()

  const onClick = useCallback(() => {
    toggleRef.current.checked = false
  }, [toggleRef])
  return (
    <div className="absolute pin-x pin-t z-50 bg-white">
      <div className="px-3 lg:mx-auto max-w-screen-xl">
        <header className="py-4 md:py-5">
          <div className="flex flex-wrap items-center">
            <div
              style={{ margin: '-5px 0' }}
              className="md:flex md:w-2/6 flex-1 items-center"
            >
              <Link to="/" className="flex items-center  md:justify-center logo">
                <img
                  src={Logo}
                  title={siteTitle}
                  alt={siteTitle}
                  style={{ maxHeight: 56 }}
                />
              </Link>
            </div>

            <label
              htmlFor="menu-toggle"
              className="pointer-cursor block lg:hidden pl-5"
            >
              <svg
                className="fill-current text-gray-900"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <title>menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </label>
            <input
              className="hidden"
              type="checkbox"
              id="menu-toggle"
              ref={toggleRef}
            />

            <div
              className="hidden lg:flex lg:items-center lg:w-auto w-full lg:flex-1"
              id="menu"
            >
              <nav>
                <ul className="list-reset flex flex-col lg:flex-row">
                  <li className="nav-item">
                    <Link to="/" activeClassName="font-bold" onClick={onClick}>
                      Home
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/projects"
                      activeClassName="font-bold"
                      onClick={onClick}
                    >
                      Projects
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/services"
                      activeClassName="font-bold"
                      onClick={onClick}
                    >
                      Services
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/about"
                      activeClassName="font-bold text-black"
                      onClick={onClick}
                    >
                      About
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/contact"
                      activeClassName="font-bold text-black"
                      onClick={onClick}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </div>
  )
}

export default Header
