import React from 'react'
import { Link } from 'gatsby'

import Logo from '../../images/9a.png'

export default function Footer() {
  return (
    <footer className="py-16 md:py-24">
      <div className={'container'}>
        <div className={'flex flex-col md:flex-row'}>
          <div className="w-full md:w-3/5 text-center md:text-left">
            <Link
              to="/"
              className="text-grey-dark hover:text-black no-underline"
            >
              <img
                src={Logo}
                alt="Logo"
                className={'w-64 md:w-auto'}
                style={{ maxHeight: '5rem' }}
              />
            </Link>
            <div className="mt-4 md:mt-6 text-base md:text-xl max-w-sm">
              Honest, efficient, and eco-conscious, trust Matys Construction
              with turning your house into your dream home.
            </div>
            <div className={'pt-4'}>
              <div
                className="fb-like"
                data-href="https://www.facebook.com/matyscarpentry"
                data-width=""
                data-layout="button_count"
                data-action="like"
                data-size="large"
                data-share="true"
              ></div>
            </div>
          </div>

          <div className="h-6 md:hidden"></div>

          <div className={'flex w-full md:w-2/5 justify-between '}>
            <ul className="list-reset px-2 mb-3 md:mt-3 md:mb-0 md:w-1/2 leading-loose my-3 text-left">
              <li className="font-semibold md:mb-3 text-black text-base md:text-lg uppercase">
                Information
              </li>
              <li>
                <Link
                  to="/about"
                  className="text-grey hover:text-black no-underline"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="text-grey hover:text-black no-underline"
                >
                  Services
                </Link>
              </li>
            </ul>

            <ul className="list-reset px-2 mb-3 md:mb-0 md:w-1/2 leading-loose my-3 md:text-left">
              <li className="font-semibold md:mb-3 text-black text-base md:text-lg uppercase">
                Contact
              </li>
              <li>
                <span className="text-grey inline-flex items-center">
                  (203)253-6358
                </span>
              </li>
              <li>
                <span className="text-grey  inline-flex items-center">
                  gpmatys@gmail.com
                </span>
              </li>
              <li>
                <span className="text-grey inline-flex items-center">
                  257 Ely Ave, <br />
                  Norwalk, CT 06854
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
