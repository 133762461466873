import React from 'react'

import Layout from './src/components/Layout'

export const wrapRootElement = ({ element }) => element
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

const HeadComponents = [
  <div id="fb-root"></div>,
  <script
    async
    defer
    crossorigin="anonymous"
    src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=759713437952152&autoLogAppEvents=1"
    nonce="XyS8bPvT"
  ></script>
]

export const onRenderBody = ({ setHeadComponents }, pluginOptions) => {
  setHeadComponents(HeadComponents)
}
