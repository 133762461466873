import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ToastContainer } from 'react-toastify'

import SEO from '../SEO'
import Header from './Header'
import Footer from './Footer'

import 'react-toastify/dist/ReactToastify.css'
import '../../styles/main.css'
import 'react-image-lightbox/style.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import MoveToTopButton from '../MoveToTopButtons'

const toastOptions = {
  position: 'bottom-center',
  draggable: false,
  toastClassName: 'text-xl bg-black text-white text-center p-3 shadow-none',
  progressClassName: 'bg-white opacity-25',
  closeButton: false
}

export default function Layout({ children }) {
  const { site, categories, collections } = useStaticQuery(categoriesQuery)

  return (
    <>
      <SEO />
      <Header siteTitle={site.siteMetadata.title} collections={collections} />
      <main>{children}</main>
      <Footer categories={categories} />
      <ToastContainer {...toastOptions} />
      {/* <MoveToTopButton /> */}
    </>
  )
}

const categoriesQuery = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
